
import Vue, { PropType } from 'vue';

import {
  FilterType,
  MakeSearchFilters_makeSearch_category,
  MakeSearchFilters_makeSearch_facets,
  MakeSearchFilters_makeSearch,
  MakeSearchQueryInput,
} from '~/apollo-api/types';

import ButtonUi from '~/components/common/button.vue';
import IconUi from '~/components/common/icon.vue';

import SearchFilterCategoryDs from '~/modules/search/components/filters/search-filter-category.vue';
import searchResetFilters from '~/modules/search/components/filters/search-reset-filters.vue';
import SearchFilterItemDs, {
  getComponentByFilterType,
} from '~/modules/search/components/filters/search-filter-item.vue';

export default Vue.extend({
  components: {
    ButtonUi,
    IconUi,
    SearchFilterCategoryDs,
    SearchFilterItemDs,
    searchResetFilters,
  },
  inject: ['media'],
  props: {
    hasCategoryFilter: {
      type: Boolean,
      default: false,
    },
    isCatalog: {
      type: Boolean,
      default: false,
    },
    facets: {
      type: Array as PropType<MakeSearchFilters_makeSearch_facets[] | null>,
      default: null,
    },
    searchFilters: {
      type: Object as PropType<MakeSearchFilters_makeSearch | null>,
      default: null,
    },
    category: {
      type: Object as PropType<MakeSearchFilters_makeSearch_category | null>,
      default: null,
    },
    queryInput: {
      type: Object as PropType<MakeSearchQueryInput>,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      filters: null,
    };
  },

  computed: {
    categoryTree() {
      if (this.searchFilters) {
        if (this.searchFilters.category && this.searchFilters.category.children) {
          return this.searchFilters.category.children;
        } else if (this.searchFilters.categoryTree) {
          return this.searchFilters.categoryTree;
        }
        return null;
      }
      return null;
    },
    hasFilters() {
      return this.$route.query.filters ? !!this.$route.query.filters.length : false;
    },
  },

  methods: {
    hasFilterComponent(type: FilterType) {
      return getComponentByFilterType(type);
    },
    cleanFilters() {
      this.filters = null;
      this.$emit('update-query-input', this.filters);
    },
    setFilter(filters) {
      this.filters = filters;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (!this.media.isMobileOrTablet) {
        this.$emit('update-query-input', this.filters);
      }
    },
    submitFilters() {
      this.$emit('update-query-input', this.filters);
      this.onClose();
    },
    onToggle() {
      this.isOpen = !this.isOpen;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (this.media.isMobileOrTablet && this.isOpen) {
        const headerContainer = document.getElementsByClassName('ds-header-container')[0] as HTMLElement;
        headerContainer.style.zIndex = '0';
      }
    },
    onClose() {
      this.isOpen = false;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (this.media.isMobileOrTablet) {
        const headerContainer = document.getElementsByClassName('ds-header-container')[0] as HTMLElement;
        headerContainer.style.zIndex = '100';
      }
    },
  },
});
