<template lang="pug">
.reset-filters(@click="cleanFilters")
  .reset-filters-text Очистить фильтры
  icon-ui(name="operations/close_gray" size="14")
</template>

<script>
import IconUi from '~/components/common/icon.vue';
export default {
  components: {
    IconUi,
  },
  methods: {
    cleanFilters() {
      this.$emit('clean-filters');
    },
  },
};
</script>

<style lang="stylus" scoped>
.reset-filters
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 10px;
  cursor pointer
  border-radius: 16px;
  background: #E4E6EB;
  height: 32px
  width: 169px
  +Media(Laptop, Desktop, DesktopMax)
    margin-left: 15px
  +Media(Mobile, Tablet)
    margin: 16px 0
  .reset-filters-text
    color: #1F1F26;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 123.077% */
</style>
