<template lang="pug">
header(id='main-header-container' class="ds-header-container")
  subheader-ds(v-if="!media.isMobileOrTablet")
  .header-container(id="main-header" :class="isShowSearch ? '' : 'without-search'")
    .ds-header.ds-component
      .ui-wrapper.header-wrapper
        button-ui.header-menu(@click="toggleMenu")
          icon-ui(:name="menuIcon" size="28")
        logo
        header-catalog-ds(
          :is-catalog-open="isCatalogOpen"
          :is-show-close-icon="isShowCloseIcon"
          @toggle="toggleCatalog"
          @close="closeCatalog"
        ).header-catalog
        .header-search(v-if="isShowSearch")
          search-suggestions(@close-menu="isMenuOpen = false")
        .header-actions
          button-ui.action.username(@click.stop="onCabinetClick")
            labeled-icon-ds(name="navigation/cabinet_sharp" size="28") {{ username }}
          link-ui.action(:to="{ name: 'favorite' }" rel="nofollow")
            labeled-icon-ds(name="operations/add-to-favorites_line" size="28") Избранное
          client-only
            link-ui.action(:to="{ name: 'cart' }" rel="nofollow")
              labeled-icon-ds(name="navigation/bag_sharp" size="24" :badge="cartCount") Корзина
  .header-categories-container(id="category-header")
    header-categories-ds(
      @toggle-catalog="toggleCatalog"
      @more-click="handleMoreClick"
    )

  mobile-menu(
    v-if="media.isMobileOrTablet"
    :is-menu-open="isMenuOpen"
    :is-catalog-open="isCatalogOpen"
    @toggle="toggleCatalog"
    @close="closeCatalog"
    @close-menu="closeMenu"
  )
</template>

<script>
import { mapGetters } from 'vuex';
import Logo from '~/components/common/logo';
import HeaderCatalogDs from './catalog';
import HeaderCategoriesDs from './header-categories';
import MobileMenu from './mobile-menu';
import { SearchSuggestions } from '~/modules/search-suggestions';
import { HeaderActionsMixin } from '~/mixins/header-actions-mixin';
import ButtonUi from '~/components/common/button';
import IconUi from '~/components/common/icon';
import LinkUi from '~/components/common/link';
import LabeledIconDs from '~/components/labeled-icon';
import SubheaderDs from '~/components/subheader';

const NO_SCROLL_CLASS = 'no-scroll-menu';

export default {
  components: {
    SearchSuggestions,
    ButtonUi,
    IconUi,
    LinkUi,
    Logo,
    HeaderCatalogDs,
    HeaderCategoriesDs,
    MobileMenu,
    LabeledIconDs,
    SubheaderDs,
  },

  mixins: [HeaderActionsMixin],

  inject: ['media'],

  data: () => ({
    isMenuOpen: false,
    isCatalogOpen: false,
    isSearchOpen: false,
    isShowCloseIcon: false,
  }),

  computed: {
    ...mapGetters('cart', ['getList']),
    cartCount() {
      return this.getList.reduce((sum, item) => sum + item.amount, 0);
    },
    menuIcon() {
      return this.isMenuOpen ? 'navigation/dismiss_sharp' : 'navigation/menu_sharp';
    },
    isShowSearch() {
      if (this.media.isMobileOrTablet) {
        return this.$route.name !== 'signup';
      }
      return true;
    },
  },

  watch: {
    $route() {
      if (document) {
        this.closeMenu();
      }
    },
  },

  mounted() {
    if (process.client) {
      window.addEventListener('scroll', this.scrollHandler);
    }
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler);
  },

  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      document.body.classList.toggle(NO_SCROLL_CLASS);
      const outTop = document.getElementById('top-info');
      if (outTop) {
        if (document.getElementById('__nuxt').style.marginTop == '-27px') {
          document.getElementById('__nuxt').style.marginTop = '0px';
        } else {
          document.getElementById('__nuxt').style.marginTop = '-27px';
        }
      }
    },
    closeMenu() {
      this.isMenuOpen = false;
      this.closeCatalog();
      document.body.classList.remove(NO_SCROLL_CLASS);
    },
    toggleCatalog() {
      this.isCatalogOpen = !this.isCatalogOpen;
      this.isShowCloseIcon = false;
    },
    closeCatalog() {
      this.isCatalogOpen = false;
      this.isShowCloseIcon = false;
    },
    handleMoreClick() {
      this.isCatalogOpen = true;
      this.isShowCloseIcon = true;
    },
    scrollHandler() {
      if (!this.media.isMobileOrTablet) {
        const header = document.getElementById('main-header');
        const categoryHeader = document.getElementById('category-header');
        if (window.scrollY > 0) {
          if (header) {
            header.classList.add('fixed-header');
            categoryHeader.style.paddingTop = '70px';
          }
        } else {
          if (header) {
            header.classList.remove('fixed-header');
            categoryHeader.style.paddingTop = '0px';
          }
        }
      }
    },
  },
};
</script>

<style lang="stylus">
.ds-header-container
  background-color: #fff
  box-shadow: 0px -4px 14px rgba(43,44,132,.2);
  +Media(Mobile, Tablet)
    padding-bottom 8px
    position sticky
    top 0
    background-color White()
    z-index 100

  .header-categories-container
    display none

    +Media(Laptop, Desktop, DesktopMax)
      display block

.header-container
  background-color White()
  padding 8px 0
  display flex
  align-items stretch
  position relative

  +Media(DesktopMax, Desktop)
    min-height 70px

  +Media(Laptop)
    min-height 60px

  +Media(Mobile, Tablet)
    padding 0
    min-height 84px
    height 84px
    display flex
    flex-direction column

  .ui-wrapper
    display flex
    align-items stretch

    +Media(Mobile, Tablet)
      padding 0

  .ds-header-logo
    +Media(Desktop, DesktopMax)
      margin-right 32px

    +Media(Mobile, Tablet, Laptop)
      margin-right 24px

.ds-header
  position relative
  width 100%
  display flex
  align-items stretch

  .header-wrapper
    display flex
    align-items center

    +Media(Mobile, Tablet)
      padding 0 12px

  .header-menu
    display none
    margin-right 12px

    +Media(Mobile, Tablet)
      display block

    svg path
      fill BlueBrand() !important

  .header-catalog
    margin-right 12px
    display none

    +Media(Laptop, Desktop, DesktopMax)
      display block

  .header-search
    width 100%

    +Media(Laptop, Desktop, DesktopMax)
      margin-right 40px
      display block

  .header-actions
    HeaderActions()
    margin-left auto

    .action
      HeaderAction()

      &.username .label
        white-space nowrap
        overflow hidden
        text-overflow ellipsis
        max-width 75px
.fixed-header
  position fixed
  top 0px
  width 100%
  z-index 100
  box-shadow: 0px -4px 14px rgba(43,44,132,.2);

.without-search
  +Media(Mobile, Tablet)
    min-height 49px !important
    height 49px !important
</style>
