<template lang="pug">
.ds-catalog-page
  catalog-seo(v-if="category" :catalog="category")
  search-ds(:additional-query-input='additionalQueryInput' search-type="catalog")
</template>

<script>
import { getCategoryById } from '~/apollo-api/getCategoryById';

import SearchDs from '~/modules/search/components/index.vue';
import { isEqualArray } from '~/modules/common/equal-array';
import CatalogSeo from './components/catalog-seo.vue';

export default {
  components: { SearchDs, CatalogSeo },
  props: {
    dshopCategoryId: {
      type: [String, Number],
      default: undefined,
    },
  },

  data() {
    return {
      category: undefined,
    };
  },
  async fetch() {
    const { params } = this.$route;
    if (params.dshopCategoryId) {
      await getCategoryById(Number(params.dshopCategoryId))
        .then((data) => {
          this.category = data;
          if (!this.category) {
            this.$nuxt.error({ statusCode: 404, message: 'Категория не найдена' });
          }
        })
        .catch((err) => {
          this.$nuxt.error({ statusCode: 404, message: 'Категория не найдена' });
        });
    }
  },

  fetchOnServer() {
    return this.$device.isCrawler;
  },

  computed: {
    additionalQueryInput() {
      return {
        categoryId: this.dshopCategoryId,
      };
    },

    query() {
      return this.$route.query.query || '';
    },
    sort() {
      return +this.$route.query.sort || 0;
    },
    price() {
      return this.$route.query.price || [undefined, undefined];
    },
    dshopFilterCategories() {
      const result = this.$route.query.cat || [];
      return Array.isArray(result) ? result : [result];
    },
    url() {
      return {
        query: this.query,
        dshopCategoryId: this.dshopCategoryId,
        dshopFilterCategories: this.dshopFilterCategories,
        sort: this.sort,
        price: this.price,
      };
    },
  },

  beforeDestroy() {
    this.$eventManager.off('updateQuery', this.onUpdateQuery);
  },

  mounted() {
    this.$eventManager.on('updateQuery', this.onUpdateQuery);
  },

  methods: {
    onUpdateQuery({ name, value }) {
      if (name === 'price' && this.$route.query.price) {
        if (!!this.$route.query.price[0] && +this.$route.query.price[0] < +this.$route.query.price[1]) {
          value[0] = this.$route.query.price[0];
        }
      }
      const query = { ...this.$route.query };
      const params = {
        ...this.$route.params,
        toPosition: { y: Math.min(window.scrollY, this.$refs.searchResult.$refs.content.offsetTop) },
      };
      name = name === 'dshopFilterCategories' ? 'cat' : name;
      if (name === 'dshopCategoryId') {
        params[name] = value;
        this.$router.replace({ name: 'catalog', params, query: { ...query, cat: undefined } }); // filter categories delete
        return;
      }
      if (isEqualArray(this.$route.query[name], value)) {
        // does not update same value
        return;
      }
      if (value === undefined) {
        delete query[name];
      } else {
        query[name] = value;
      }
      this.$router.replace({ query, params });
    },
  },
};
</script>
