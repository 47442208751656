import { render, staticRenderFns } from "./checkout-postcode.vue?vue&type=template&id=6589d962&lang=pug"
import script from "./checkout-postcode.vue?vue&type=script&lang=js"
export * from "./checkout-postcode.vue?vue&type=script&lang=js"
import style0 from "./checkout-postcode.vue?vue&type=style&index=0&id=6589d962&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports