<template lang="pug">
.main-page
  client-only
    banner-section-ds.ui-wrapper(section="MAIN.MAIN" )

  .ui-wrapper
    shop-heading-ds(v-if="static.description")
    client-only
      top-categories-ds
  offers-ds
  .ui-wrapper
    main-seo-text(:isWrapping="true" v-if="static.isShowSEOText")
</template>

<script>
import IconUi from '~/components/common/icon';
import BannerSectionDs from '~/components/banner-section.vue';
import ShopHeadingDs from '~/components/shop-heading';
import TopCategoriesDs from '~/components/top-categories';
import OffersDs from '~/components/offers';
import MainBlocksProfit from '~/components/main-blocks-profit';
import MainSeoText from '~/components/main-seo-text';
import { StaticDataMixin } from '~/mixins/staticData';
import { CheckProductAmountMixin } from '~/mixins/business/checkProductAmount';
import { SeoDataMixin } from '~/mixins/seoData';
import SpinnerUi from '~/components/spinner.vue';
export default {
  components: {
    IconUi,
    BannerSectionDs,
    ShopHeadingDs,
    TopCategoriesDs,
    OffersDs,
    MainBlocksProfit,
    MainSeoText,
    SpinnerUi,
  },

  mixins: [StaticDataMixin, CheckProductAmountMixin, SeoDataMixin],

  head() {
    if (this.$device.isCrawler) {
      return this.seoData[this.seoDataPages.ALL](this.$route.path);
    }
  },

  async created() {
    if (process.client) {
      this.checkAmount(this.$store.state.cart.list);
    }
  },
};
</script>

<style lang="stylus">
.main-page
  .ds-main-seo-text
    p
      color #8B8E99
  .spinner-center
    display: flex
    align-items center
    justify-content: center
</style>
