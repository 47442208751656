
import Vue, { PropType } from 'vue';
import { mapState, mapActions } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import { search, searchFilters } from '~/apollo-api/search';
import {
  FilterInputType,
  MakeSearch_makeSearch,
  MakeSearchFilters_makeSearch,
  MakeSearchQueryInput,
} from '~/apollo-api/types';

import { isEqualArray } from '~/modules/common/equal-array';

import SpinnerUi from '~/components/spinner.vue';
import Pagination from '~/components/common/pagination.vue';

import SearchBreadcrumbsDs from '~/modules/search/components/search-breadcrumbs.vue';
import SearchCategoryDs from '~/modules/search/components/search-category.vue';
import SearchFormDs from '~/modules/search/components/search-form.vue';
import SearchProductsDs from '~/modules/search/components/search-products.vue';

import { DEFAULT_QUERY_INPUT, DEFAULT_PAGINATION, DEFAULT_FILTERS_PAGINATION } from '~/modules/search/constants';
import { getFiltersFromUrl } from '~/modules/search/utils/get-filters-from-url';
import AddedToFavorite from '~/components/popups/add-favorite-popup.vue';
import { add, remove } from '~/apollo-api/favorite';
import { PopupsService } from '~/modules/popups/services/popups-service';
import { PopupsContainerModel } from '~/modules/popups/models';
import { getProduct } from '~/apollo-api/getProduct';
import { DESC_SORT_VALUE } from '~/modules/search/constants/sort-data';
import { StaticDataMixin } from '~/mixins/staticData';
import AdultNotification from './adult-notification.vue';

const LOADING_DISTANCE = 300;
const PAGINATION_LIMIT = 40;

export default Vue.extend({
  components: {
    InfiniteLoading,
    SpinnerUi,
    SearchFormDs,
    SearchProductsDs,
    SearchBreadcrumbsDs,
    SearchCategoryDs,
    AddedToFavorite,
    AdultNotification,
    Pagination,
  },
  mixins: [StaticDataMixin],

  props: {
    hasCategoryFilter: {
      type: Boolean,
      default: false,
    },
    additionalQueryInput: {
      type: Object as PropType<Partial<MakeSearchQueryInput>>,
      default: () => {},
    },
    searchType: {
      type: String,
    },
    title: {
      type: String,
    },
  },

  data() {
    return {
      loadingDistance: LOADING_DISTANCE,
      queryInput: DEFAULT_QUERY_INPUT,
      filters: null as MakeSearchFilters_makeSearch | null,
      data: null as MakeSearch_makeSearch | null,
      // eslint-disable-next-line @typescript-eslint/ban-types
      throttledInfiniteSearch: undefined as Function | undefined,
      isNeedFullUpdate: false,
      searchPermission: false,
      totalPages: 0,
      isLoading: false,
    };
  },
  async fetch() {
    this.setInitialQueryInput();
    // this.throttledInfiniteSearch = throttle(this.onInfiniteSearch, THROTTLE_TIME);
    if (process.client) {
      if (this.cardsFromCache.items.length) {
        this.data = this.cardsFromCache;
        await this.getSearchFilters();
        if (this.data) {
          this.totalPages = Math.ceil(this.data.total / PAGINATION_LIMIT);
        }
        return;
      }
    }
    await this.getSearchFilters();
    await this.setPage();
  },
  fetchOnServer() {
    return this.$device.isCrawler;
  },
  computed: {
    askAdult() {
      return this.$store.state.main.askAdult;
    },
    isCatalog() {
      return this.searchType === 'catalog';
    },
    cardsFromCache() {
      return this.$store.getters['cache/cards'];
    },
    hasCategoryChildren() {
      if (this.filters) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return !!(this.filters.category || this.filters.categoryTree);
      }
      return false;
    },
  },

  watch: {
    '$route.query': {
      deep: true,
      async handler() {
        if (this.searchType != 'search') {
          await this.setPage();
          await this.getSearchFilters();
        }
      },
    },
    queryInput: {
      deep: true,
      handler(newValue, oldValue) {
        this.updateQueryRoute(newValue, oldValue);
      },
    },
    additionalQueryInput: {
      deep: true,
      async handler(newVal, oldVal) {
        if (newVal && oldVal) {
          if (newVal.text != oldVal.text) {
            this.queryInput = DEFAULT_QUERY_INPUT;
            this.filters = null;
          }
        }
        await this.getSearchFilters();
        await this.setPage();
      },
    },
  },
  async created() {
    if (!this.$device.isCrawler) {
      await this.getSearchFilters();
    }
  },

  mounted() {
    if (this.$route.name === 'catalog') {
      this.searchPermission = !!(this.additionalQueryInput && this.additionalQueryInput.categoryId);
    } else {
      this.searchPermission = true;
    }
    if (process.client) {
      const isSortByDesc = !!this.isDescPriceSort();
      if (isSortByDesc) {
        this.queryInput = {
          ...this.queryInput,
          sort: DESC_SORT_VALUE,
        };
      }
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
  },

  methods: {
    ...mapActions({
      setSearchCards: 'cache/setSearchCards',
      setSearchQuery: 'cache/setSearchQuery',
    }),
    resetInfiniteSearch() {
      if (this.data) {
        this.data = {
          ...this.data,
          items: [],
        };
      }
      this.isNeedFullUpdate = true;
    },
    async setPage() {
      try {
        this.data = null;
        this.isLoading = true;

        this.queryInput = {
          ...this.queryInput,
          pagination: {
            ...this.queryInput.pagination,
            offset: 0,
          },
        };
        if (this.$route.query.page) {
          this.queryInput = {
            ...this.queryInput,
            pagination: {
              ...this.queryInput.pagination,
              offset: (Number(this.$route.query.page) - 1) * PAGINATION_LIMIT,
            },
          };
        }
        const response = await search({
          ...this.queryInput,
          ...this.additionalQueryInput,
        });
        if (response && response.items?.length) {
          this.data = {
            ...response,
            items: response.items,
          };
          this.totalPages = Math.ceil(response.total / PAGINATION_LIMIT);
          this.isNeedFullUpdate = false;
        }
        this.setSearchCards(JSON.parse(JSON.stringify(this.data)));
      } catch {
        this.$nuxt.error({ statusCode: 404, message: 'Что-то пошло не так' });
      } finally {
        this.isLoading = false;
      }
    },
    isDescPriceSort() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const categories = this.$store.getters['catalog/flattenSortByDesc'];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const descCatalogList = this.static.DescCatalogList;
      const activeCategoryId = this.additionalQueryInput
        ? this.additionalQueryInput.categoryId
          ? this.additionalQueryInput.categoryId
          : null
        : null;
      const searchText = this.additionalQueryInput
        ? this.additionalQueryInput.text
          ? this.additionalQueryInput.text.toLowerCase()
          : null
        : null;
      if (activeCategoryId && categories) {
        return categories.find((category) => category.id == activeCategoryId);
      } else if (searchText && descCatalogList && descCatalogList.length) {
        return descCatalogList.find((item) => searchText.includes(item));
      }
      return false;
    },
    setInitialQueryInput() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { query } = this.$route;
      const queryInput = {};
      // eslint-disable-next-line no-prototype-builtins
      if (query.hasOwnProperty('filters')) {
        const { filters } = query;
        if (Array.isArray(filters)) {
          queryInput['filters'] = filters.map((filter) => getFiltersFromUrl(filter!));
        } else {
          queryInput['filters'] = [getFiltersFromUrl(filters)];
        }
      }
      // eslint-disable-next-line no-prototype-builtins
      if (query.hasOwnProperty('sort')) {
        const { sort } = query;
        if (Array.isArray(sort)) {
          queryInput['sort'] = sort[0];
        } else {
          queryInput['sort'] = sort;
        }
      }

      // eslint-disable-next-line no-prototype-builtins
      if (query.hasOwnProperty('categoryId')) {
        queryInput['categoryId'] = query.categoryId;
      }

      this.updateQueryInput(queryInput);
    },
    updateQueryInput(newValue) {
      const newQueryInput = {
        ...this.queryInput,
        pagination: DEFAULT_PAGINATION,
      };

      if (!newValue) {
        this.queryInput = {
          ...newQueryInput,
          filters: [],
        };
        this.filters = null;
        return;
      }

      // eslint-disable-next-line no-prototype-builtins
      if (newValue.hasOwnProperty('filterType')) {
        this.queryInput = {
          ...newQueryInput,
          filters: [...this.queryInput.filters.filter((value) => value.id !== newValue.id), newValue],
        };
        return;
      }

      this.queryInput = {
        ...newQueryInput,
        ...newValue,
      };
    },
    updateQueryRoute(newValue: MakeSearchQueryInput, oldValue: MakeSearchQueryInput) {
      const params = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...this.$route.params,
      } as any;

      if (!isEqualArray(newValue.filters, oldValue.filters)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete this.$route.query.page;
        this.$router.replace({
          params,
          query: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...this.$route.query,
            filters: newValue.filters.map((filter) => {
              if (filter.filterType === FilterInputType.RANGE) {
                return `${filter.filterType}~${filter.id}:${filter.range!.min}~${filter.range!.max}`;
              }
              return '';
            }),
          },
        });
      }

      if (newValue.sort !== oldValue.sort) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete this.$route.query.page;
        this.$router.replace({
          params,
          query: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...this.$route.query,
            sort: newValue.sort,
          },
        });
      }

      if (newValue.categoryId !== oldValue.categoryId) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete this.$route.query.page;
        this.$router.replace({
          params,
          query: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...this.$route.query,
            categoryId: newValue.categoryId,
          },
        });
      }
    },
    async getSearchFilters() {
      try {
        const response = await searchFilters(
          {
            ...this.queryInput,
            ...this.additionalQueryInput,
            pagination: DEFAULT_FILTERS_PAGINATION,
          },
          this.isCatalog,
        );

        if (!response) {
          this.filters = null;
          return;
        }
        this.filters = response;
        this.totalPages = Math.ceil(this.filters.total / PAGINATION_LIMIT);
      } catch (error) {
        this.$nuxt.error({ statusCode: 404, message: 'Что-то пошло не так' });
      }
    },
    showAddedToFavoritePopup(added) {
      PopupsService.open({
        type: PopupsContainerModel.ETypeWrapper.TOP_RIGHT_NOTIFY,
        component: AddedToFavorite,
        propsData: {
          added,
        },
      });
    },
    async favoriteChange(ids) {
      const product = await getProduct(ids[1]);
      const sku = this.data?.items?.find((_) => _.catalogCard.id === ids[0]);
      if (sku && sku.catalogCard.favorite) {
        await remove([ids[0]]);
        this.showAddedToFavoritePopup(false);
        this.$store.dispatch('main/updateProductFavoriteStatus', ids);
        this.$store.dispatch('viewedProducts/updateProduct', ids);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.$gtm.push(this.$dataLayer.removeFromFavoriteEvent(product, product.skuList[0]));
      } else {
        await add([ids[0]]);
        this.showAddedToFavoritePopup(true);
        this.$store.dispatch('main/updateProductFavoriteStatus', ids);
        this.$store.dispatch('viewedProducts/updateProduct', ids);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.$gtm.push(this.$dataLayer.addToFavoriteEvent(product, product.skuList[0]));
      }
      if (sku) {
        // console.log('itWorks');
        // this.$store.dispatch('main/updateProductFavoriteStatus', [ids[1], ids[0], !ids[2]]);
        // this.$store.dispatch('viewedProducts/updateProduct', [ids[1], ids[0], !ids[2]]);
        ///need fix
        sku.catalogCard.favorite = !sku.catalogCard.favorite;
      }
    },
  },
});
