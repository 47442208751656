<template lang="pug">
div
</template>

<script>
import { SeoDataMixin } from '~/mixins/seoData';
export default {
  mixins: [SeoDataMixin],
  props: {
    catalog: {
      type: Object,
      default: () => {},
    },
  },
  head() {
    return this.seoData[this.seoDataPages.CATALOG](this.catalog, this.$route.path);
  },
};
</script>
