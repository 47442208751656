<template lang="pug">
ul.pagination(v-if="pages > 0")
  template(v-if="this.$device.isCrawler")
    li(
      v-for="(page, index) in fullRange"
      :key="`page-${index}`"
      :class="['pagination__page', { 'pagination__page--active': activePage === page, 'pagination__page-last': index === paginationRange.length }]"
      @click="handlePageClick(page)"
    )
      link-ui(:to="urlLinkTo(page)") {{ page }}
  template(v-else)
    li(:class="['pagination__prev', { disabled: activePage === 1 }]" @click="handlePrevClick()")
      link-ui(:to="urlLinkTo(activePage - 1)")
        icon-ui(name="navigation/right")
        span(v-if="!media.isMobileOrTablet") Назад
    template(v-for="(page, index) in paginationRange")
      li(
        v-if="page > 0"
        :key="`page-${index}`"
        :class="['pagination__page', { 'pagination__page--active': activePage === page, 'pagination__page-last': index === paginationRange.length }]"
        @click="handlePageClick(page)"
      )
        link-ui(:to="urlLinkTo(page)") {{ page }}
      li.pagination__page.pagination__page--ellipsis(v-else) ...
    li(:class="['pagination__next', { disabled: activePage === pages }]" @click="handleNextClick()")
      link-ui(:to="urlLinkTo(activePage + 1)")
        span(v-if="!media.isMobileOrTablet") Вперед
        icon-ui(name="navigation/right")
</template>

<script>
import Vue from 'vue';
import LinkUi from '~/components/common/link.vue';
import IconUi from '~/components/common/icon.vue';
import { routerUtils } from '~/mixins';

const PAGES_MAX_VIEW_LEN = 6;
const PAGE_ELLIPSIS_NUM = 0;
const PAGE_QUERY_NAME = 'page';

export default Vue.extend({
  name: 'Pagination',
  components: {
    LinkUi,
    IconUi,
  },
  mixins: [routerUtils],
  inject: ['media'],
  props: {
    pages: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activePage: Number,
      url: String,
    };
  },
  computed: {
    fullRange() {
      const range = [];
      if (this.$device.isCrawler) {
        if (this.pages > 150) {
          for (let i = 1; i <= 150; i++) {
            range.push(i);
          }
        } else {
          for (let i = 1; i <= this.pages; i++) {
            range.push(i);
          }
        }
      }
      return range;
    },
    paginationRange() {
      const range = [];
      if (this.pages <= PAGES_MAX_VIEW_LEN) {
        for (let i = 1; i <= this.pages; i++) {
          range.push(i);
        }
      } else {
        range.push(1);

        const nearStart = this.activePage < PAGES_MAX_VIEW_LEN - 1;
        const nearEnd = this.activePage > this.pages - 2;

        if (nearStart) {
          const end = Math.min(PAGES_MAX_VIEW_LEN - 1, this.pages - 1);
          for (let i = 2; i <= end; i++) range.push(i);
          if (this.pages > PAGES_MAX_VIEW_LEN) range.push(PAGE_ELLIPSIS_NUM);
        } else if (nearEnd) {
          if (this.activePage > this.pages - 3) range.push(PAGE_ELLIPSIS_NUM);
          for (let i = Math.max(this.pages - 3, 2); i <= this.pages; i++) range.push(i);
        } else {
          if (this.activePage !== PAGES_MAX_VIEW_LEN - 2) range.push(PAGE_ELLIPSIS_NUM);
          else range.push(this.activePage - 2);
          for (let i = this.activePage - 1; i <= this.activePage + 1; i++) range.push(i);
          range.push(PAGE_ELLIPSIS_NUM);
        }

        if (this.activePage === this.pages - 2) {
          range.pop();
          range.push(this.pages);
        }
      }
      return range;
    },
  },
  mounted() {
    const { path, query } = this.$route;
    const possiblePageNumber = query[PAGE_QUERY_NAME];
    if (possiblePageNumber && /^\d+$/.test(possiblePageNumber) && Number(possiblePageNumber) <= this.pages)
      this.activePage = Number(query[PAGE_QUERY_NAME]);
    else this.activePage = 1;
    this.url = path;
  },
  methods: {
    urlLinkTo(page) {
      if (!this.url || page < 1 || page > this.pages) return '';
      else if (page === 1) return this.removeQueryParam(PAGE_QUERY_NAME);
      else return this.updateQueryParam(PAGE_QUERY_NAME, page);
    },
    handlePageClick(page) {
      this.activePage = page;
    },
    handleNextClick() {
      this.handlePageClick(this.activePage + 1);
    },
    handlePrevClick() {
      this.handlePageClick(this.activePage - 1);
    },
  },
});
</script>

<style lang="stylus">
.pagination
  Text(BodyM Medium Short)
  display flex
  align-items center
  user-select: none;

  li
    cursor pointer
    height 40px

    &:nth-last-of-type(2)
      margin-right 0

    a
      width 100%
      height 100%
      display: flex
      align-items: center
      justify-content: center
      justify-content center

  &__prev
    margin-right 16px

    span
      margin-left 12px

    svg
      transform rotate(-180deg)

  &__next
    margin-left 16px

    span
      margin-right 12px

  &__prev
  &__next
    display flex
    align-items center

    &:hover
      color Blue()

      svg path
        fill Blue()

    &.disabled
      color TextTertiary()
      cursor default
      pointer-events none

      a
        cursor default
        pointer-events none

      svg path
        fill TextTertiary()

  &__page
    display flex
    align-items center
    justify-content center
    min-width 40px
    border-radius 4px
    margin-right 2px

    &--active
      border 1px solid TextPrimary()

    ../ &--ellipsis
      cursor default

    &:hover:not(&--active):not(&--ellipsis)
      background-color InputFill()
      color Blue()
</style>
