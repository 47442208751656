export const loginSuccessEvent = function (number) {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'personal_account_login_submit_success',
    eventCategory: 'personal_account',
    eventAction: 'login',
    eventLabel: 'submit_success',
    properties: {
      phone_part: number,
    },
  };
};

export const loginErrorEvent = function (number) {
  return {
    event: 'UNIVERSAL_EVENT',
    event_name: 'personal_account_login_submit_failed',
    eventCategory: 'personal_account',
    eventAction: 'login',
    eventLabel: 'submit_failed',
    properties: {
      phone_part: number,
    },
  };
};
