<template lang="pug">
.ds-offers-page
  offer-seo(v-if="offer" :offer="offer")
  search-ds(
    has-category-filter
    :additional-query-input='additionalQueryInput'
    search-type="offer"
    :title='offer ? offer.title : "" '
  )
    .ui-wrapper(slot='header')
      h1.offers-title {{ offer ? offer.title : '' }}
</template>

<script>
import { dShopOffer } from '~/apollo-api/offer';
import SearchDs from '~/modules/search/components/index.vue';
import { isEqualArray } from '~/modules/common/equal-array';
import OfferSeo from './components/offer-seo.vue';
export default {
  components: { SearchDs, OfferSeo },

  props: {
    offerID: {
      type: [String, Number],
      default: undefined,
    },
  },

  data() {
    return {
      offer: undefined,
    };
  },

  async fetch() {
    const { params } = this.$route;
    await dShopOffer(Number(params.offerID))
      .then((data) => {
        if (!data) {
          this.$nuxt.error({ statusCode: 404, message: 'Подборка не найдена' });
          return;
        }
        this.offer = data;
      })
      .catch((err) => this.$nuxt.error({ statusCode: 404, message: 'Подборка не найдена' }));
  },

  fetchOnServer() {
    return this.$device.isCrawler;
  },

  computed: {
    additionalQueryInput() {
      return {
        offerCategoryId: this.offerID,
      };
    },
    sort() {
      return +this.$route.query.sort || 0;
    },
    price() {
      return this.$route.query.price || [undefined, undefined];
    },
    dshopFilterCategories() {
      const result = this.$route.query.cat || [];
      return Array.isArray(result) ? result : [result];
    },
  },

  mounted() {
    this.$eventManager.on('updateQuery', this.onUpdateQuery);
  },

  beforeDestroy() {
    this.$eventManager.off('updateQuery', this.onUpdateQuery);
  },

  methods: {
    onUpdateQuery({ name, value }) {
      const query = { ...this.$route.query };
      const params = {
        ...this.$route.params,
        toPosition: { y: Math.min(window.scrollY, this.$refs.searchResult.$refs.content.offsetTop) },
      };
      name = name === 'dshopFilterCategories' ? 'cat' : name;
      if (name === 'dshopCategoryId') {
        params[name] = value;
        this.$router.replace({ name: 'catalog', params, query: { ...query, cat: undefined } }); // filter categories delete
        return;
      }
      if (isEqualArray(this.$route.query[name], value)) {
        // does not update same value
        return;
      }
      if (value === undefined) {
        delete query[name];
      } else {
        query[name] = value;
      }
      this.$router.replace({ query, params });
    },
  },
};
</script>

<style lang="stylus" scoped>
.ds-offers-page
  .offers-title
    Text(TitleL Medium Secondary)
    margin 32px 0 16px
    min-height 24px
    +Media(Tablet, Mobile)
      Text(TitleM Medium Short Secondary)
</style>
