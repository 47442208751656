<template lang="pug">
div
</template>

<script>
import { SeoDataMixin } from '~/mixins/seoData';
export default {
  mixins: [SeoDataMixin],
  props: {
    offer: {
      type: Object,
      default: () => {},
    },
  },
  head() {
    return this.seoData[this.seoDataPages.OFFER](this.offer, this.$route.path);
  },
};
</script>
