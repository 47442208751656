<template lang="pug">
.app(:class="`page-${$route.name}`")
  header-ds
    logo
  main(class="page-content")
    nuxt
  footer-ds(v-if="$route.name == 'delivery-points' ? !isMobileOrTablet :  true")
  client-only
    popups-container-ds
</template>

<script>
import Common from './common';
import SignInPopupMixin from '~/mixins/signInPopupMixin';
import HeaderDs from '~/components/header';
import LinkUi from '~/components/common/link';
import Logo from '~/components/common/logo';
import { PRODUCTS_SIZE } from '~/modules/offer/data';
import SubheaderDs from '~/components/subheader';
import FooterDs from '~/components/footer';
import PopupsContainerDs from '~/modules/popups/popups-container';
import { PopupsService } from '~/modules/popups/services/popups-service';
import { PopupsContainerModel } from '~/modules/popups/models';
import { staticData } from '~/shop-config';

export default Common.extend({
  name: 'DefaultLayout',
  components: {
    PopupsContainerDs,
    SubheaderDs,
    HeaderDs,
    FooterDs,
    LinkUi,
    Logo,
  },
  mixins: [SignInPopupMixin],
  data() {
    return {
      isShowDeliveryBanner: staticData.isShowDeliveryBanner,
      crawler: this.$device.isCrawler,
    };
  },
  watch: {
    size: {
      handler(value) {
        if (value && process.client) {
          this.getMainData(PRODUCTS_SIZE[value], this.isMobileOrTablet);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (process.client) {
      this.$eventManager.on('open-signin-popup', this.openSignInPopup);
      this.$eventManager.on('entry-end', this.closePopup);
      this.$eventManager.on('registration-end', this.closePopup);
      this.getMainCategories();
    }
  },
  beforeDestroy() {
    this.$eventManager.off('open-signin-popup', this.openSignInPopup);
    this.$eventManager.off('entry-end', this.closePopup);
    this.$eventManager.off('registration-end', this.closePopup);
  },
  methods: {
    closePopup() {
      if (this.$route.name !== 'signin') {
        PopupsService.close(PopupsContainerModel.ETypeWrapper.CENTER);
      }
    },
    onLogoClick() {
      const name = 'home';

      if (this.$route.name === name) {
        return;
      }

      this.$router.push({ name });
    },
  },
});
</script>

<style lang="stylus">
.no-scroll-menu
  +Media(Mobile, Tablet)
    overflow hidden
</style>

<style lang="stylus" scoped>
.app
  position relative
  display flex
  flex-direction column
  min-height 100vh

  .page-content
    flex-grow 1
    padding-top 16px

  &.page-product
  &.page-cart
    .ds-footer
      +Media(Mobile, Tablet)
        display none
</style>
