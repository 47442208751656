<template lang="pug">
.ds-signin(@keyup.enter="proceed")
  .signin-title Войдите, чтобы видеть ваши заказы и сохраненные товары

  .signin-form
    .input-login.input-row
      labeled-input-ds(
        mask="+7 ### ###-##-##"
        type="tel"
        autocomplete="tel"
        v-model="phone"
        ref="input"
        placeholder='+7'
        :transform="phoneTransform"
        :is-error="!!error.length"
        label="Номер телефона:"
      )
        .action(
          slot="after"
          v-if="isValidatePhone")
            icon-ui.check(name="status/check_line")

    .input-password.input-row
      labeled-input-ds(
        :type="passwordInputType"
        v-model="password"
        autocomplete="current-password"
        :is-error="!!error.length"
        label="Введите пароль:"
      )
        .action(
          slot="after"
          @click.stop="togglePasswordVisibility"
          v-if="truePhone.length === 10"
        )
          icon-ui(:name="passwordActionIconName")
    label.error(v-if="error.length" v-html="error")
    .form-actions.input-row
      button-ui(
        :disabled="!canProceed || isProccessing"
        @click="proceed"
      ) Войти
        spinner-ds(
          slot="before"
          v-if="isProccessing"
          :size="20"
        )
    .signin-footer
      button-ui.to-restore(@click.stop="$emit('change-tab', 'restore')") Забыли пароль?
</template>

<script>
import IconUi from '~/components/common/icon';
import SpinnerDs from '~/components/spinner';
import ButtonUi from '~/components/common/button';
import LabeledInputDs from '~/components/labeled-input';
import { signIn } from '~/apollo-api/signIn';
import { phoneTransform } from '~/utils/phoneTransform';
import { setRecipient } from '~/store/checkout';

import { getSkuIdsForMerge, add } from '~/apollo-api/favorite';

export default {
  components: {
    LabeledInputDs,
    IconUi,
    ButtonUi,
    SpinnerDs,
  },
  inject: ['initPhone'],
  data() {
    return {
      phone: '',
      password: '',
      isPasswordVisible: false,
      isProccessing: false,
      error: '',
      phoneTransform,
      btnDisabled: false,
      skuIds: [],
    };
  },
  computed: {
    passwordInputType() {
      return this.isPasswordVisible ? 'text' : 'password';
    },
    passwordActionIconName() {
      return this.isPasswordVisible ? 'operations/eye-hide_line' : 'operations/eye-show_line';
    },
    canProceed() {
      return this.isValidatePhone && !!this.password.length && !this.btnDisabled;
    },
    isValidatePhone() {
      return this.truePhone.length === 10;
    },
    truePhone() {
      return this.phone.replace('+7', '').replace(/[^0-9]/g, '');
    },
  },
  watch: {
    phone() {
      this.error = '';
    },
    password() {
      this.error = '';
    },
  },
  mounted() {
    this.$gtm.push(this.$dataLayer.viewAuthorization());
    this.$root.$on('disabled', (isOtherError) => (this.btnDisabled = isOtherError));
  },
  created() {
    if (this.initPhone?.length) {
      this.phone = this.initPhone;
    }
    this.getSkuIds();
  },
  methods: {
    focusPhone(activeTab) {
      if (activeTab === 'signin') {
        this.$nextTick(() => this.$refs.inputPhone.$refs.input.focus());
      }
    },
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    async proceed() {
      if (!this.canProceed) {
        return;
      }

      let isSuccess = false;

      try {
        this.isProccessing = true;

        const { accessToken, refreshToken, customer } = await signIn(this.phone, this.password);
        // add user data ---
        const userData = { ...customer.contacts, notify: false };
        const keys = Object.keys(setRecipient());
        const arr = [];
        keys.forEach((key) => {
          if (setRecipient()[key]) {
            arr.push(true);
          }
        });
        if (!arr.length) {
          this.$store.commit('checkout/updateRecipient', userData);
        }
        // ---
        this.$store.dispatch('user/logInWithToken', { accessToken, refreshToken });
        this.$store.dispatch('user/setCustomer', customer);
        this.$gtm.push(this.$dataLayer.loginSuccessEvent(this.phone));
        isSuccess = true;
      } catch (error) {
        console.error(error);
        this.$gtm.push(this.$dataLayer.loginErrorEvent(this.phone));
        const errors = error.graphQLErrors.map((x) => x.extensions?.exceptionClass);
        if (errors.includes('InvalidGrantException')) {
          this.error = 'Неверный номер или пароль';
        } else if (errors.includes('TimeoutNotExpiredException')) {
          this.error = 'Превышено количество попыток';
        } else {
          this.error = 'Что-то пошло не так. Повторите попытку';
        }
        isSuccess = false;
      } finally {
        this.isProccessing = false;
        if (isSuccess) {
          await add(this.skuIds);
          this.$eventManager.emit('entry-end');
        }
      }
    },
    async getSkuIds() {
      await getSkuIdsForMerge().then((data) => {
        this.skuIds = data.flatMap((sku) => sku.id);
      });
    },
  },
};
</script>

<style lang="stylus">
.ds-signin
  padding-top 40px

  .signin-title
    Text(TitleS)
    text-align center

  .signin-form
    .input-row
      margin-top 24px

      label
        .ui-input
          margin-top 8px

      .ui-input
        Input(Outlined)

        +Media(Laptop, Desktop, DesktopMax)
          Input(Large)

        +Media(Mobile, Tablet)
          Input(Medium)
    .input-login
      .action
        padding 6px
        .check path
          fill Green(LT4)
    .input-password
      .action
        cursor pointer
        padding 6px
        display flex
        align-items center

        path
          fill Gray(L16)

        &:hover
          path
            fill Gray(D32)

    .form-actions
      .ui-button
        Button(Large Primary)
        width 100%
        .ds-spinner
          margin-right 10px
    .signin-footer
      .to-restore
        Button()
        Text(BodyM Semibold)
        width 100%
        margin 32px auto 0
</style>
