<template lang="pug">
.ds-offers
  .ui-wrapper(v-if="preloadOffers.length")
    offer-ds(
      v-for="offer in preloadOffers"
      :key="offer.id"
      :id="offer.id"
      :title="offer.title"
      :products="offer.productsPage.content"
    )
</template>

<script>
import OfferDs from '~/components/offer';
import MainBlocksProfitDs from '~/components/main-blocks-profit';
import { getMainOffers } from '~/apollo-api/getMainOffers';
import InfiniteLoading from 'vue-infinite-loading';
import SpinnerUi from '~/components/spinner.vue';
export default {
  components: {
    OfferDs,
    MainBlocksProfitDs,
    SpinnerUi,
    InfiniteLoading,
  },
  data() {
    return {
      preloadOffers: [],
      allOffers: [],
      page: 0,
    };
  },
  async fetch() {
    if (this.$device.isCrawler) {
      const preload = (await getMainOffers()).offers;
      const filteredOffers = preload.filter((offer) => {
        return offer.productsPage && offer.productsPage.content.length > 0;
      });
      this.preloadOffers = filteredOffers;
    }
  },
  computed: {
    storeOffers() {
      if (process.client) {
        return this.$store.getters['main/getOffers'];
      }
      return [];
    },
  },
  watch: {
    storeOffers: {
      deep: true,
      handler() {
        this.preloadOffers = this.storeOffers;
      },
    },
  },
  fetchOnServer() {
    return this.$device.isCrawler;
  },
  mounted() {
    if (process.client) {
      this.preloadOffers = this.storeOffers;
    }
  },
};
</script>

<style lang="stylus">
.ds-offers
  .ds-main-blocks-profit
  .ds-offer
    margin-bottom 56px
    +Media(Mobile, Tablet)
      margin-bottom 40px
  .search-spinner
    margin 56px auto
</style>
