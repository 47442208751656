<template lang="pug">
  component(:is="asyncComponent" @updateFavorite="getFavorite")
</template>

<script>
import { FeedbackSortType } from '~/apollo-api/types';
import { ESkuGroupType } from '~/modules/search/models';
import { getProductPage } from '~/apollo-api/getProductPage';
import { getSkuGroupsByIdProduct } from '~/apollo-api/favorite';
import { computed } from 'vue';
import Layoutable from '~/plugins/layoutable/mixin';

export default {
  mixins: [Layoutable(['mobile', 'desktop'])],
  provide() {
    return {
      product: computed(() => this.product),
      feedbacks: computed(() => this.feedbacks),
    };
  },
  data() {
    return {
      crawler: this.$device.isCrawler,
      product: null,
      feedbacks: null,
    };
  },

  async fetch() {
    const productId = Number(this.$route.params.id);

    const characteristicIds = this.$route.query[ESkuGroupType.CharacteristicId];
    const characteristicValueIds = this.$route.query[ESkuGroupType.CharacteristicValueId];
    let productPage = null;
    let skuGroup = {};
    if (characteristicIds && characteristicValueIds) {
      skuGroup = {
        characteristicIds: Array.isArray(characteristicIds)
          ? characteristicIds.map(Number)
          : [Number(characteristicIds)],
        characteristicValueIds: Array.isArray(characteristicValueIds)
          ? characteristicValueIds.map(Number)
          : [Number(characteristicValueIds)],
      };
    }

    await getProductPage({
      productPageId: productId,
      sort: FeedbackSortType.RELEVANCE,
      page: 0,
      size: 10,
    })
      .then((data) => {
        productPage = data;
        if (!productPage) {
          this.$nuxt.error({ statusCode: 404, message: 'Продукт не найден' });
        } else {
          this.product = {
            ...productPage.product,
            skuGroup,
          };
          this.feedbacks = [];
        }
      })
      .catch((err) => {
        this.$nuxt.error({ statusCode: 404, message: 'Продукт не найден' });
      });
  },
  fetchOnServer() {
    return this.$device.isCrawler;
  },
  mounted() {
    if (process.client && this.product) {
      this.$gtm.push(this.$dataLayer.productOpenEvent(this.product));
    }
  },
  methods: {
    async getFavorite() {
      if (process.client && this.product) {
        const data = await getSkuGroupsByIdProduct(Number(this.$route.params.id));
        this.product.skuGroups = data.skuGroups;
      }
    },
    async import(l) {
      return await import('./' + l);
    },
  },
};
</script>
