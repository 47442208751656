import Vue from 'vue';

export const routerUtils = Vue.extend({
  methods: {
    updateQueryParam(key, value) {
      const basePath = this.$route.path;
      const query = { ...this.$route.query, [key]: value };
      return `${basePath}?${this.createQueryString(query)}`;
    },
    removeQueryParam(key) {
      const basePath = this.$route.path;
      const { [key]: omitted, ...restQuery } = this.$route.query;
      const queryString = this.createQueryString(restQuery);
      return queryString ? `${basePath}?${queryString}` : basePath;
    },
    createQueryString(query) {
      return Object.entries(query)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&');
    },
  },
});
