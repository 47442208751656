import { client, gql } from '~/apollo-api';
import {
  MakeSearch,
  MakeSearch_makeSearch,
  MakeSearchFilters,
  MakeSearchFilters_makeSearch,
  MakeSearchQueryInput,
} from '~/apollo-api/types';
import { skuGroupCardFragment } from '~/apollo-api/fragments/catalog-cards/skuGroupCard';
import { categoryFragment, categoryShortFragment } from '~/apollo-api/fragments/category';
import { facetFragment } from '~/apollo-api/fragments/facet';

export const searchFilters = async (
  queryInput: MakeSearchQueryInput,
  isCatalog: boolean,
): Promise<MakeSearchFilters_makeSearch | undefined> => {
  const query = () => {
    return isCatalog
      ? gql`
          query MakeSearchFilters($queryInput: MakeSearchQueryInput!) {
            makeSearch(query: $queryInput) {
              queryText
              category {
                ...CategoryShortFragment
              }
              facets {
                ...FacetFragment
              }
              total
            }
          }
          ${facetFragment}
          ${categoryShortFragment}
        `
      : gql`
          query MakeSearchFilters($queryInput: MakeSearchQueryInput!) {
            makeSearch(query: $queryInput) {
              queryText
              category {
                ...CategoryShortFragment
              }
              categoryTree {
                category {
                  id
                }
                total
              }
              facets {
                ...FacetFragment
              }
              total
            }
          }
          ${facetFragment}
          ${categoryShortFragment}
        `;
  };
  const { data } = await client.query<MakeSearchFilters | null>({
    query: query(),
    variables: {
      queryInput,
    },
  });

  return data?.makeSearch;
};

export const search = async (queryInput: MakeSearchQueryInput): Promise<MakeSearch_makeSearch | undefined> => {
  const { data } = await client.query<MakeSearch | null>({
    query: gql`
      query MakeSearch($queryInput: MakeSearchQueryInput!) {
        makeSearch(query: $queryInput) {
          items {
            catalogCard {
              ...SkuGroupCardFragment
            }
          }
          total
          mayHaveAdultContent
        }
      }
      ${skuGroupCardFragment}
    `,
    variables: {
      queryInput,
    },
  });

  return data?.makeSearch;
};
