<template lang="pug">
.ds-product-card(:class="{ 'unavailable-card': !areYouAdult }" @click="checkAdult")
  link-ui(:to="link" @click.native="clickCard")
    .image-wrapper
      .are-you-adult(v-if="!areYouAdult")
        template(v-if="askAdult")
          icon-ui(name="status/hide_product_eye" size="32px")
          p Товар для взрослых
      .image
        img-smooth(:src="mainPhoto" :alt="cardData.title")
      button-favorite.add-to-favorites(
        @click.prevent.native.stop="clickFavoritesButton(!cardData.favorite)"
        :active="cardData.favorite"
      )
      client-only
        product-badges-ds.product-badges(v-bind="badgesProps")
    .price(v-if="cardData.skuGroup")
      .current(:class=" cardData.minSellPrice !== cardData.minFullPrice ? 'discount' : ''") {{ cardData.minSellPrice | formatPrice }}
      .old(v-if="cardData.minSellPrice !== cardData.minFullPrice") {{ cardData.minFullPrice | formatPrice }}
    .price(v-else)
      .current(:class="skuWithMinPrice.sellPrice !== skuWithMinPrice.fullPrice ? 'discount' : ''") {{ skuWithMinPrice.sellPrice | formatPrice }}
      .old(v-if="skuWithMinPrice.sellPrice !== skuWithMinPrice.fullPrice") {{ skuWithMinPrice.fullPrice | formatPrice }}
    .title {{ cardData.title }}
    //- .available-sku-groups(v-if="itsFavorite" @click.stop.prevent="fastAddCart") {{ availableSkuGroupsText }}
    .product-card-feedback-container
      product-card-feedback-ds(
        v-if="cardData.rating"
        :rating="cardData.rating"
        :feedback-quantity="cardData.feedbackQuantity"
      )
    .actions
      button-ui.add-to-cart(@click.prevent.native="fastAddCart" :disabled="!areYouAdult") В корзину
</template>

<script>
import { mapState } from 'vuex';
import { getProduct } from '~/apollo-api/getProduct';
import ButtonUi from '~/components/common/button';
import IconUi from '~/components/common/icon';
import LinkUi from '~/components/common/link';
import ProductCardFeedbackDs from '~/components/product-card-feedback';
import ProductBadgesDs from '~/components/product-badges';
import { currencyFormatMixin } from '~/mixins';
import { AddCartMixin } from '~/mixins/business/addCart';
import { PopupsContainerModel } from '~/modules/popups/models';
import { PopupsService } from '~/modules/popups/services/popups-service';
import AddedToCartPopup from '~/components/popups/added-to-cart-popup';
import SelectSkuPopup from '~/components/popups/select-sku-popup';
import ProductUnavailablePopup from '~/components/popups/product-unavailable-popup';
import AdultNotifyPopup from '~/components/popups/adult-notify-popup';
import { ESkuGroupType } from '~/modules/search/models';
import { staticData } from '~/shop-config';
import ButtonFavorite from '~/components/common/button-favorite.vue';
import ending from '~/modules/common/ending';

export default {
  components: {
    IconUi,
    ButtonUi,
    LinkUi,
    ProductCardFeedbackDs,
    ProductBadgesDs,
    ButtonFavorite,
    AdultNotifyPopup,
  },

  mixins: [currencyFormatMixin, AddCartMixin],

  inject: ['media'],

  props: {
    hasActions: {
      type: Boolean,
      default: true,
    },
    cardData: {
      type: Object,
      default: () => ({}),
    },
    itemListName: {
      type: String,
      default: null,
    },
    itemIndex: {
      type: Number,
      default: null,
    },
    itsFavorite: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('main', ['askAdult', 'showAdult']),
    link() {
      let query = {};

      if (this.$device.isCrawler) {
        return {
          name: 'product',
          params: { id: this.cardData.id },
        };
      }

      if (this.$route.name === 'home') {
        if (
          this.skuWithMinPrice &&
          this.skuWithMinPrice.characteristicValues &&
          this.skuWithMinPrice.characteristicValues.length
        ) {
          const characteristicIds = this.skuWithMinPrice.characteristicValues.map((item) => {
            return item.characteristic.id;
          });
          const characteristicValueIds = this.skuWithMinPrice.characteristicValues.map((item) => {
            return item.id;
          });
          query = {
            ...query,
            [ESkuGroupType.CharacteristicId]: characteristicIds,
            [ESkuGroupType.CharacteristicValueId]: characteristicValueIds,
          };
        }
      }

      if (this.cardData.skuGroup) {
        query = {
          ...query,
          [ESkuGroupType.CharacteristicId]: this.cardData.skuGroup.characteristicIds,
          [ESkuGroupType.CharacteristicValueId]: this.cardData.skuGroup.characteristicValueIds,
        };
      }

      return {
        name: 'product',
        params: { id: this.cardData.id },
        query,
      };
    },

    mainPhoto() {
      const photos = this.cardData?.photos;
      const isPhotosExist = photos && photos.length;

      return isPhotosExist ? photos[0]?.link?.high : '';
    },
    areYouAdult() {
      if (this.cardData?.adult) {
        return this.showAdult;
      } else {
        return true;
      }
    },
    skuWithMinPrice() {
      const skuList = [...this.cardData.skuList];
      const minAvailable = skuList.sort((a, b) => a.sellPrice - b.sellPrice);
      if (minAvailable.filter((a) => a.availableAmount > 0)[0]) {
        return minAvailable.filter((a) => a.availableAmount > 0)[0];
      }
      return minAvailable[0];
    },
    badgesProps() {
      return {
        ...(this.cardData.skuGroup
          ? {
              sellPrice: this.cardData?.minSellPrice,
              fullPrice: this.cardData?.minFullPrice,
            }
          : {
              sellPrice: this.skuWithMinPrice?.sellPrice,
              fullPrice: this.skuWithMinPrice?.fullPrice,
            }),
        badges: staticData.isShowBadges ? this.cardData.badges : [],
      };
    },
    availableSkuGroupsText() {
      if (!this.itsFavorite) return '';
      if (!this.cardData.characteristic.length) return '';
      const available = this.cardData.characteristic[0].characteristic.values.length;
      return `еще ${available} ${ending(available, ['вариант', 'варианта', 'вариантов'])}`;
    },
  },

  methods: {
    async fastAddCart() {
      const product = await getProduct(this.cardData.id);
      this.$dataLayer.setCatalogItemIndex(this.cardData.index || this.itemIndex);
      this.$dataLayer.setCatalogItemListName(this.itemListName);
      this.$dataLayer.setFavoriteItemIndex(this.cardData.index || this.itemIndex);
      this.$dataLayer.setFavoriteItemListName(this.itemListName);

      if (product.skuList.length === 1) {
        // without choice
        if (!product.skuList[0].availableAmount) {
          PopupsService.open({
            type: PopupsContainerModel.ETypeWrapper.TOP_RIGHT_STICKY,
            component: ProductUnavailablePopup,
          });
        } else {
          await this.addToCart(product, product.skuList[0], 1, true);
          this.$gtm.push(this.$dataLayer.addToCartEvent(product, product.skuList[0], 1));
          PopupsService.open({
            type: PopupsContainerModel.ETypeWrapper.TOP_RIGHT_NOTIFY,
            component: AddedToCartPopup,
            propsData: {
              sku: product.skuList[0],
              product: this.cardData,
              photo: this.mainPhoto,
              count: 1,
            },
          });
        }
      } else {
        if (this.itsFavorite) {
          if (product.characteristics.length > 1) {
            PopupsService.open({
              type: PopupsContainerModel.ETypeWrapper.CENTER,
              component: SelectSkuPopup,
              propsData: {
                ...product,
                id: this.cardData.id,
                itsFavorite: this.itsFavorite,
                characteristicSkuGroup: this.cardData.characteristic,
              },
            });
          } else if (product.skuGroups.length < product.skuList.length) {
            PopupsService.open({
              type: PopupsContainerModel.ETypeWrapper.CENTER,
              component: SelectSkuPopup,
              propsData: {
                ...product,
                id: this.cardData.id,
                characteristicSkuGroup: this.cardData.characteristic,
              },
            });
          } else {
            const sku = product.skuList.find((el) => {
              return (
                el.characteristicValues[0].characteristic.id === this.cardData.characteristic[0].characteristic.id &&
                el.characteristicValues[0].id === this.cardData.characteristic[0].id
              );
            });
            if (sku && sku.availableAmount) {
              await this.addToCart(product, sku, 1, true);
              this.$gtm.push(this.$dataLayer.addToCartEvent(product, sku, 1));
              PopupsService.open({
                type: PopupsContainerModel.ETypeWrapper.TOP_RIGHT_NOTIFY,
                component: AddedToCartPopup,
                propsData: {
                  sku: sku,
                  product: this.cardData,
                  photo: this.mainPhoto,
                  count: 1,
                },
              });
            } else {
              PopupsService.open({
                type: PopupsContainerModel.ETypeWrapper.TOP_RIGHT_STICKY,
                component: ProductUnavailablePopup,
              });
            }
          }
          return;
        }

        PopupsService.open({
          type: PopupsContainerModel.ETypeWrapper.CENTER,
          component: SelectSkuPopup,
          propsData: {
            ...product,
            id: this.cardData.id,
          },
        });
      }
    },
    async clickCard() {
      const product = await getProduct(this.cardData.id);

      this.$gtm.push({ ecommerce: null });
      this.$gtm.push(
        this.$dataLayer.selectProductEvent(
          product,
          product.skuList[0],
          this.itemListName,
          this.itemIndex ?? this.cardData.index,
        ),
      );
    },
    clickFavoritesButton(status) {
      this.$emit('favorite-change', [this.cardData.skuGroupId, this.cardData.id, status, this.cardData]);
    },
    checkAdult() {
      if (this.askAdult && this.cardData?.adult) {
        PopupsService.open({
          type: PopupsContainerModel.ETypeWrapper.CENTER,
          component: AdultNotifyPopup,
        });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
$content
  margin-top 8px
  padding 0 8px
  height 3em
  display -webkit-box
  -webkit-line-clamp 2
  -webkit-box-orient vertical
  overflow hidden
  text-overflow ellipsis

.ds-product-card
  flex-shrink 0

  .ui-link
    display grid
  &:hover
    .image
      transform scale(1.05, 1.05)

    .actions
      .add-to-cart
        background-color OrangeBrandHover()
        color White()

  &:last-child
    margin-right 0

  .image-wrapper
    Round(Medium)
    width 100%
    padding-top calc(100% * 4 / 3)
    position relative
    overflow hidden
    z-index 0
    .are-you-adult
      align-items: center;
      flex-direction: column;
      justify-content: center
      gap 8px
      height: 100%;
      display flex
      left: 0;
      font-size: 12px;
      position: absolute;
      cursor pointer
      top: 0;
      width: 100%;
      z-index: 1;
      background-color: rgba(250, 250, 250, 0.65);

    .image
      position absolute
      top 0
      bottom 0
      left 0
      right 0
      transition ease-in all .2s
      background #EFEFEF

      img
        display block
        width 100%
        height 100%
        object-fit cover
        transition opacity .3s ease-in
        opacity 0
        &.loaded
          opacity 1

  .title
    Text(BodyM Long)
    @extend $content

  .price
    padding 0 8px
    margin-top 12px
    display flex
    align-items center
    +Media(Tablet, Mobile)
      align-items: flex-end

    .current
      Text(BodyL Semibold Short)

    .old
      Text(BodyM Short LineThrough)
      color TextSecondary()
      margin-left 8px
      +Media(Tablet, Mobile)
        Text(BodyS Short LineThrough)
      @media (max-width: 380px) {
        font-size: 10px !important
      }

  .actions
    margin-top 8px
    padding 8px

    .add-to-cart
      Text(BodyM Semibold Short)
      Button(Medium)
      background-color ButtonGray()
      color TextPrimary()
      line-height 18px
      transition all 0.3s

      &:active
        background-color OrangeBrandPressed()
        color White()

      +Media(Tablet, Mobile)
        width 100%

  .product-card-feedback-container
    margin-top 12px
    padding 0 8px
    height 16px

  .product-badges
    position absolute
    bottom 4px
    margin-left 4px
    z-index 2
.add-to-favorites
  position absolute
  top 5px
  right 5px
  border none
  z-index 2
.available-sku-groups
  Text(CaptionM Short)
  color TextSecondary()
  padding 0 8px
  margin-top 5px
.unavailable-card
  position relative
  &::before
    content: ' '
    align-items: center;
    flex-direction: column;
    height: 100%;
    left: 0;
    cursor pointer
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  .image
    img
      filter: blur(20px)
  &:hover
    .actions
      .add-to-cart
        background-color ButtonGrayDisabled()
        color TextDisabled()
</style>
