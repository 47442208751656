export const state = () => ({
  cities: [],
  banners: [],
  offers: [],
  deliveryInfo: null,
  dshopTexts: null,
  showAdult: false,
  askAdult: true,
});

export const mutations = {
  updateItem(state, { name, value }) {
    state[name] = value;
  },
  updateState(state, payload) {
    state = payload;
  },
  updateAdult(state, payload) {
    state.showAdult = payload;
    state.askAdult = false;
  },
  //payload:[skuGroupId, productId, status]
  updateProductFavoriteStatus(state, payload) {
    state.offers = state.offers.map((offer) => {
      if (offer.productsPage.content.length) {
        const product = offer.productsPage.content.find((product) => product.id === payload[1]);
        if (product) {
          if (payload[0]) {
            let skuGroup = product.skuGroups.find((skuGroup) => skuGroup.id === payload[0]);
            skuGroup.favorite = payload[2];
          } else {
            product.skuGroups[0].favorite = payload[2];
          }
        }
      }
      return {
        ...offer,
      };
    });
  },
  cleanProductFavoriteStatus(state) {
    state.offers = state.offers.map((offer) => {
      if (offer.productsPage.content.length) {
        offer.productsPage.content.map((product) => {
          product.skuGroups.map((skuGruop) => {
            skuGruop.favorite = false;
            return {
              ...skuGruop,
            };
          });
          return {
            ...product,
          };
        });
      }
      return {
        ...offer,
      };
    });
  },
  updateOffers(state, payload) {
    const { id, content } = payload;
    state.offers = state.offers.map((offer) => {
      offer.id === id ? (offer.productsPage.content = content) : null;
      return {
        ...offer,
      };
    });
  },
};

export const actions = {
  updateItem({ commit }, payload) {
    commit('updateItem', payload);
  },
  updateState({ commit }, payload) {
    commit('updateState', payload);
  },
  updateProductFavoriteStatus({ commit }, payload) {
    commit('updateProductFavoriteStatus', payload);
  },
  updateAdult({ commit }, payload) {
    commit('updateAdult', payload);
  },
  cleanProductFavoriteStatus({ commit }, payload) {
    commit('cleanProductFavoriteStatus', payload);
  },
  updateOffers({ commit }, payload) {
    commit('updateOffers', payload);
  },
};

export const getters = {
  freeDeliveryMinPrice: (state) => state.deliveryInfo?.freeDeliveryMinPrice ?? 0,
  getOffers: (state) => {
    if (state && state.offers.length) {
      const filteredOffers = state.offers.filter((offer) => {
        return offer.productsPage && offer.productsPage.content.length > 0;
      });
      return filteredOffers;
    }
    return [];
  },
};
