
import { Component, Prop, Provide, Vue } from 'vue-property-decorator';

import SignInTabDs from '~/components/sign/signin-tab.vue';

export type TSignInPopupProps = {
  formData?: {
    initPhone?: string;
    [key: string]: unknown;
  };
  isVerifyPhone?: boolean;
};

@Component({
  components: {
    SignInTabDs,
  },
})
export default class SignInPopup extends Vue {
  $gtm: any; // без них ошибки
  $dataLayer: any; // без них ошибки
  mounted() {
    this.$gtm.push(this.$dataLayer.viewAuthorization());
  }

  @Prop(Object) propsData!: TSignInPopupProps;

  @Provide() initPhone = this.propsData.formData?.initPhone;
}
