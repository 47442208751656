<template lang="pug">
.ds-first-password(@keyup.enter="proceed")
  .verify-phone-container
    .verify-phone-title {{ isRestore ? 'Придумайте новый пароль' : 'Готово, осталось придумать пароль' }}

    .verify-phone-form
      .input-login.input-row
        labeled-input-ds(
          v-model="password"
          ref="input"
          :type="passwordInputType"
          label="Введите пароль"
          autocomplete="new-password"
        )
          .action(
            slot="after"
            @click.stop="togglePasswordVisibility"
          )
            icon-ui(:name="passwordActionIconName")
      checklist-ds(:input="password" :validations="validations")
      .form-actions.input-row
        button-ui.process(
          :disabled="!canProceed || isProccessing"
          @click="proceed"
        ) {{ buttonText }}
          spinner-ds(
            slot="before"
            v-if="isProccessing"
            :size="20"
          )
</template>

<script>
import { mapActions, mapState } from 'vuex';
import IconUi from '~/components/common/icon';
import SpinnerDs from '~/components/spinner';
import ButtonUi from '~/components/common/button';
import { setupPassword } from '~/apollo-api/setupPassword';
import { restorePassword } from '~/apollo-api/restorePassword';
import LabeledInputDs from '~/components/labeled-input';
import { SignUpState } from '~/store/user';
import ChecklistDs from '~/components/checklist';

export default {
  components: {
    IconUi,
    SpinnerDs,
    ButtonUi,
    LabeledInputDs,
    ChecklistDs,
  },
  props: {
    isRestore: {
      type: Boolean,
      default: false,
    },
    isVerifyPhone: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      password: '',
      isProccessing: false,
      isPasswordVisible: false,
      validations: [
        {
          title: 'Не менее 8 символов',
          predicate: (input) => input.length >= 8,
        },
        {
          title: 'Состоит из цифр и латинских букв',
          predicate: (input) => /\d.*[a-zA-Z]|[a-zA-Z].*\d/.test(input),
        },
        {
          title: 'Содержит строчные и заглавные буквы',
          predicate: (input) => /[a-z].*[A-Z]|[A-Z].*[a-z]/.test(input),
        },
      ],
      btnDisabled: false,
    };
  },

  computed: {
    ...mapState('user', {
      hasResendCodeRequest: (state) => state.registration.hasResendCodeRequest,
    }),
    passwordInputType() {
      return this.isPasswordVisible ? 'text' : 'password';
    },
    passwordActionIconName() {
      return this.isPasswordVisible ? 'operations/eye-hide_line' : 'operations/eye-show_line';
    },
    canProceed() {
      return this.validations.every((v) => v.predicate(this.password)) && !this.btnDisabled;
    },
    truePhone() {
      return this.$store.state.user.registration.phone.replace('+7', '').replace(/[^0-9]/g, '');
    },
    buttonText() {
      if (this.isRestore) {
        return 'Войти';
      }
      if (this.isVerifyPhone) {
        return 'Продолжить оформление';
      }
      return 'Начать покупки';
    },
  },
  watch: {
    isRestore: {
      handler() {
        this.$gtm.push(this.$dataLayer.registrationPasswordSubmit(this.truePhone));
      },
    },
  },

  mounted() {
    this.$root.$on('disabled', (isOtherError) => (this.btnDisabled = isOtherError));
  },
  methods: {
    ...mapActions({
      setHasResendCodeRequest: 'user/setHasResendCodeRequest',
    }),
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    async proceed() {
      if (!this.canProceed) {
        return;
      }

      try {
        this.isProccessing = true;
        const { accessToken, refreshToken, customer } = await (this.isRestore || this.hasResendCodeRequest
          ? restorePassword
          : setupPassword)({
          phone: this.truePhone,
          password: this.password,
          code: this.$store.state.user.registration.code,
        });
        await this.$store.dispatch('user/logInWithToken', { accessToken, refreshToken });
        await this.$store.dispatch('user/setCustomer', customer);
        this.$eventManager.emit('registration-end');
        await this.$store.dispatch('user/clearRegistrationData');
        this.$gtm.push(this.$dataLayer.recoveryPasswordSubmit(this.truePhone));
      } catch (error) {
        console.error(error);
      } finally {
        this.isProccessing = false;

        if (this.hasResendCodeRequest) {
          this.setHasResendCodeRequest(false);
        }
      }
    },
    changeNumber() {
      this.$store.dispatch('user/setState', SignUpState.Phone);
    },
  },
};
</script>

<style lang="stylus">
.ds-first-password
  padding 24px 8px 0

  .header-actions
    margin-bottom 32px

    .change-number
      Button(Large Ghost)

      +Media(Mobile)
        padding-left 0

  .verify-phone-container
    margin 0 auto

    +Media(DesktopMax)
      width 350px

    +Media(Tablet, Laptop, Desktop)
      width 310px

  .verify-phone-title
    Text(TitleS)
    text-align center

    .phone
      white-space nowrap

  .verify-phone-form
    .input-row
      margin-top 24px

      label
        .ui-input
          margin-top 8px

      .ui-input
        Input(Outlined)

        +Media(Laptop, Desktop, DesktopMax)
          Input(Large)

        +Media(Mobile, Tablet)
          Input(Medium)

    .form-actions
      .ui-button

        width 100%

        &.process
          Button(Large Primary)
          .ds-spinner
            margin-right 10px
        &.repeat
          Button(Large Ghost)

          .cool-down
            padding-left 8px

.action
  cursor pointer
  padding 6px
  display flex
  align-items center

  path
    fill Gray(L16)

  &:hover
    path
      fill Gray(D32)
</style>
