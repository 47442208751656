import { setContext } from '@apollo/client/link/context';
// d-shop basic tokens
import { basicToken } from '~/shop-config';
import Cookies from 'js-cookie';
import { v4 as guidGenerator } from 'uuid';

export const getBearerToken = () => {
  if (process.client) {
    const storage = Cookies.get('auth');
    if (!storage) {
      return null;
    }
    return JSON.parse(storage).accessToken;
  }
  return null;
};

export const IID_LOCAL_STORAGE_KEY = 'iid';
export const getInstallId = () => {
  let iidFromLocalStorage: undefined | string;
  if (process.client) {
    iidFromLocalStorage = Cookies.get(IID_LOCAL_STORAGE_KEY);

    if (!iidFromLocalStorage) {
      iidFromLocalStorage = guidGenerator() as string;
      Cookies.set(IID_LOCAL_STORAGE_KEY, iidFromLocalStorage, {
        expires: 14,
        secure: process.env.NODE_ENV !== 'development',
      });
    }

    return iidFromLocalStorage;
  }
  iidFromLocalStorage = guidGenerator() as string;
  return iidFromLocalStorage;
};

// authLink – just need it to work with tokens in headers
export const authLink = setContext((_, { headers }) => {
  const bearerToken = getBearerToken();
  if (process.client) {
    return {
      headers: {
        ...headers,
        authorization:
          _.operationName === 'refreshToken'
            ? `Basic ${basicToken}` // if query is refreshToken then we have expired access token
            : bearerToken
            ? `Bearer ${bearerToken}`
            : `Basic ${basicToken}`,
        'X-IID': getInstallId(),
      },
    };
  }
  return {
    headers: {
      ...headers,
      authorization: `Basic ${basicToken}`, // if query is refreshToken then we have expired access token
      'X-IID': getInstallId(),
    },
  };
});
